<template>
  <Export2 v-if="isNew" @toggleUi="isNew = $event"></Export2>
  <Export v-else @toggleUi="isNew = $event"></Export>
</template>

<script>
import Export from './Export.vue';
import Export2 from './Export2.vue';

export default {
  components: {
    Export,
    Export2,
  },
  data() {
    return {
      isNew: true,
    };
  },
  mounted() {
    const storedVal = localStorage.getItem('exportsUi');
    const isEsOrg = this.checkFeatureFlag('event-sourced-txns', this.features);
    if (isEsOrg) {
      this.isNew = !(storedVal && storedVal === 'old');
    } else {
      this.isNew =
        storedVal === 'new'
          ? true
          : storedVal === 'old'
          ? false
          : this.checkFeatureFlag('new-exports-ui', this.features);
    }
  },
};
</script>
